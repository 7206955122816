import React from "react"
import RightVectorImage from "../../images/new-landing/right-vector.svg"
import classNames from "classnames";
import {Link} from "gatsby";
import Logo from "../../images/logo.svg";

export default function Hero({setToggle, toggle, toggleVideoModal, toggleContactModal, navItemClick}) {
  const contactNavClick = () => {
    toggleContactModal();
    navItemClick("Watch how it works")
  }
  return (
    <>
      <header className="site-header site-header--nofix">
        <div className="container--full">
        <div className="header-inner">
          <nav className="flex justify-between items-center py-0 sm:py-3">
            <div className="pb-2">
              <Link to="/" ><img src={Logo} alt="luca logo" className="w-20" /></Link>
            </div>


            <ul className="block sm:flex">
              <li className="hidden sm:block" onClick={() => navItemClick("Watch how it works")}>
                <button className="play-btn focus:outline-none my-0 py-2" onClick={toggleVideoModal}>Watch how it works
                <svg className="inline ml-2" width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.5 7.94778C12.1667 7.56288 12.1667 6.60063 11.5 6.21573L1.75 0.586566C1.08333 0.201666 0.25 0.682792 0.25 1.45259V12.7109C0.25 13.4807 1.08333 13.9618 1.75 13.5769L11.5 7.94778Z" fill="white" />
                </svg>
              </button>
              </li>
              <li> <a className="hover:text-mint ml-8 cursor-pointer" onClick={contactNavClick}>Contact us</a></li>
            </ul>
          </nav>
        </div>
      </div>
      </header>

      <div className="landing-page-hero">
        <div className="container z-20 inner">
          <div className="flex flex-col sm:flex-row items-center justify-around">
            <div className="inner-left mx-auto">
              <h2 className="title text-neutral-2">
                Get paid in 5 days by the Australian Government through e-invoicing
              </h2>
              <div className="flex flex-col sm:flex-row justify-content-center items-center sm:mt-8">
                <div className="btn-demo">
                  <a
                      onClick={toggleContactModal}
                      className="flex text-base text-white focus:outline-none cursor-pointer"
                  >
                      Book a Demo
                      <img src={RightVectorImage} className="ml-2 sm:ml-3" alt="right arrow"/>
                  </a>
                </div>
                <div className="btn-agency ml-4">
                  <a
                      className="flex text-neutral-2 focus:outline-none  cursor-pointer"
                      onClick={toggleContactModal}
                  >
                    I am a Gov Agency
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
