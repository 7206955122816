
import icon1 from "../../images/e-invoicing-government/icon1.svg"
import icon2 from "../../images/e-invoicing-government/icon2.svg"
import icon3 from "../../images/e-invoicing-government/icon3.svg"

const block_data = [
  {
    icon: icon1,
    title: "Faster Payment Times",
    desc: "Get paid within 5 days from receipt of your e-invoicing",
    link: "/signup/",
    but: "Book Demo",
  },
  {
    icon: icon2,
    title: "Safe & Secure",
    desc: "By pass unsecure mail boxes, issue your e-invoice direct to your clients software",
    link: "/signup/",
    but: "Book Demo",
  },
  {
    icon: icon3,
    title: "Create Efficiency in Your Business",
    desc: "Reduce up to 70% in manual data entry",
    link: "/signup/",
    but: "Find Out More",
  }
]

export default block_data