import React from "react"
import arrowImg from "../../images/partner/g-arrow.svg"
import block_data from "./blockData"

export default function Value({toggleContactModal}) {
  return (
    <div className="value mx-auto">
      <div
        className="title text-center
       text-xl sm:text-24 font-bold leading-30 sm:leading-38"
      >
        The Australian Government has committed to 5 day payment timeframes for suppliers & government agencies
        who adopt e-invoicing.
      </div>

      <div className="blocks flex flex-col sm:flex-row sm:flex-wrap justify-between items-center">
        {block_data.map((item, index) => (
          <div className="block text-left" key={`block-x-${index}`}>
            <img src={item.icon} className="mb-6" style={{ width: 35 }} />
            <div className="title2 mb-2 font-medium text-17 text-neutral-1 leading-6">
              {item.title}
            </div>
            <div
              className="desc2 font-sans  text-neutral-3 text-sm
             mb-6 leading-6"
            >
              {item.desc}
            </div>

            <a
              onClick={toggleContactModal}
              // href={item.link}
              className="link font-medium text-secondary flex items-center leading-18 cursor-pointer"
            >
              {item.but}
              <img src={arrowImg} className="inline-block h-full ml-2" />
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
