import React, {useState} from "react"
import ReactGA from "react-ga";

import SEO from "../components/seo"
import Footer from "../components/footer"

import Trusts2 from "../components/e-invoicing-government/trusts2"
import "../scss/style.scss"

import Hero from "../components/e-invoicing-government/hero"
import Info from "../components/e-invoicing-government/info"
import Team from "../components/e-invoicing-government/team"
import Modali, { useModali } from "../components/modal"

import Value from "../components/e-invoicing-government/value";
import Number from "../components/e-invoicing-government/number";
import Tools from "../components/e-invoicing-government/tools";
import Join from "../components/e-invoicing-government/join";
import seoImage from "../images/e-invoicing-government/bg.png"

export default function NewLandingPage() {
  const [videoModal, toggleVideoModal] = useModali()
  const [contactModal, toggleContactModal] = useModali()
  const [previewModal, togglePreviewModal] = useModali()
  const [toggle, setToggle] = useState(false)
  const navItemClick = (navItem) => {
    ReactGA.event({
      category: 'NAV_ITEM',
      action: `Clicked ${navItem} Nav Item of Government Page`
    });
  }
    return (
    <div className="h-full font-robo einvoicing-governement">
      <SEO title="E-invoicing benefits: Get paid faster by the Australian Government" einvoice={true} description="Get paid in 5 days by the Australian Government through e-invoicing" image={seoImage} keywords="e-invoicing government" />
      <Hero toggleVideoModal={toggleVideoModal} toggleContactModal={toggleContactModal} setToggle={setToggle} toggle={toggle} navItemClick={navItemClick} />
      <section className="bg-white2 bg-white sm:hidden pad-block" />
      <Trusts2 />
      <Value toggleContactModal={toggleContactModal} />
      <hr />
      <Info toggleContactModal={toggleContactModal} />
      <Number />
      <Tools togglePreviewModal={togglePreviewModal} />
      <Join toggleContactModal={toggleContactModal}/>
      <Team />
      <hr />
      <Footer simple={true} />
      <Modali.Modal {...videoModal}>
        <div className="hero-video">
            <iframe
                src="https://www.youtube.com/embed/L8Q7ScsjLtQ?autoplay=1&enable_js=1"
                title="Introduction to LUCA+"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
            />
        </div>
      </Modali.Modal>
      <Modali.Modal {...previewModal}>
        <div className="hero-video">
            <iframe
                  src="https://www.youtube.com/embed/Ya1XE9NuKNU"
                  title="Introduction to LUCA+"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  frameBorder="0"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
            >
            </iframe>
        </div>
      </Modali.Modal>
      <Modali.Modal {...contactModal} hubspot={true}>
        <div className="hero-hubspot">
          <iframe
              src="https://meetings.hubspot.com/ray-wang/meeting-with-luca-plus"
              title="Introduction to LUCA Plus"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
          />
        </div>
      </Modali.Modal>
    </div>
  )
}
