
const steps_data = [
    {
        title: "Step 1",
        desc: "The supplier signs up to LUCA Plus, (PEPPOL secure access point).",
        link: "/signup/",
        but: "Find Out More",
    },
    {
        title: "Step 2",
        desc: "The supplier creates and sends out an invoice within their accounting software, LUCA Plus then converts it into an e-invoice.",
        link: "/signup/",
        but: "Book Demo",
    },
    {
        title: "Step 3",
        desc: "Then, the e-invoice is received automatically by the Government agency who is ready to process payment.",
        link: "/signup/",
        but: "Find Out More",
    }
]

export default steps_data