import React from "react"
import t1Img from "../../images/partner/t1.svg"
import t2Img from "../../images/partner/t2.svg"
import t3Img from "../../images/partner/t3.svg"
import arrowImg from "../../images/partner/g-arrow.svg"
import Modali, { useModali } from "../../components/modal"

export default function Tools({togglePreviewModal}) {
  const [videoModal, toggleVideoModal] = useModali()
  return (
    <div className="tools">
      <div className="container">
        <div className="title">Still unsure what the difference between an invoice and an e-invoice is?</div>
        <div className="desc">
          Check out LUCA Plus’ e-invoicing resources below to find out the answers and much more
        </div>
        <div className="blocks text-center flex flex-col sm:flex-row justify-between items-center">
          <div className="block ">
            <img src={t1Img} className="mx-auto" />
            <div className="title uppercase">SHARE</div>
            <div className="descc2">
              e-invoicing myth busters. Download and share to help educate your
              clients
            </div>
            <div className="x">
              <a
                href="https://hubs.ly/H0ww6yx0"
                className="link flex items-center"
                target="_blank"
              >
                DOWNLOAD & SHARE NOW
                <img
                  src={arrowImg}
                  className=" inline-block h-full  w-5 ml-1"
                />
              </a>
            </div>
          </div>
          <div className="block">
            <img src={t2Img} className="mx-auto" />
            <div className="title uppercase">Watch</div>
            <div className="descc2">
              Missed our webinar with the ATO’s e-invoicing Director, Mark
              Stockwell?
            </div>
            <div className="x">
              <a
                onClick={togglePreviewModal}
                className="link flex items-center cursor-pointer"
              >
                Watch a preview NOW
                <img
                  src={arrowImg}
                  className=" inline-block h-full ml-1 w-5 "
                />
              </a>
            </div>
          </div>
          <div className="block">
            <img src={t3Img} className="mx-auto" />
            <div className="title uppercase">DOWNLOAD</div>
            <div className="descc2 ">
              Our FREE eBook provides you and your clients with rich insights on
              the benefits e-invoicing brings to all Australian businesses.
            </div>
            <div className="x">
              <a
                href="/ebook/"
                className="link link3   flex justify-center items-center"
              >
                READ & SHARE
                <img src={arrowImg} className=" inline-block h-full ml-1 " />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Modali.Modal {...videoModal}>
        <div className="hero-video">
          <iframe
            src="https://www.youtube.com/embed/iBxwD5pqr8E?autoplay=1&enable_js=1"
            title="Introduction to LUCA+"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            allow="autoplay"
          />
        </div>
      </Modali.Modal>
    </div>
  )
}
