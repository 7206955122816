import React from "react"

export default function Number() {
  return (
    <div className="number mx-auto">
      <div className="flex flex-col sm:flex-row justify-between items-center container">
        <div className="block">
          <div className="title">89%</div>
          <div className="desc">
            The percentage of SME’s still processing invoices manually
          </div>
        </div>
        <div className="block  head">
          <div className="title">$29.27</div>
          <div className="desc">
            The average cost to process <br /> a manual invoice
          </div>
        </div>
        <div className="block head">
          <div className="title">75%</div>
          <div className="desc">
            The number of businesses that experienced late payments in 2018 &
            2019
          </div>
        </div>
      </div>
    </div>
  )
}
