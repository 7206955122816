import React from "react"
import calendarImg from "../../images/e-invoicing-government/calendar.svg"
import bookOpenImg from "../../images/e-invoicing-government/book_open.svg"
import {Link} from "gatsby";

export default function Join({toggleContactModal}) {

  return (
    <div className="join bg-brand py-10">
      <div className="inner text-white mx-auto">
        <div className="title text-24 sm:text-30 leading-9 sm:leading-40 font-bold ">
          Find out how LUCA Plus can help you implement e-invoicing for you to start getting paid on time
        </div>

        <div className="flex flex-col sm:flex-row justify-center">
          <Link
              // to={`/signup/`}
              // state={{ isFindOut: true, partner: true }}
          >
            <div className="card mt-8 mx-auto rounded bg-white flex justify-center  items-center" onClick={toggleContactModal}>
              <div className=" flex flex-col justify-center items-center">
                <img src={bookOpenImg} className="" />
                <div className="text-secondary mt-1 uppercase">Find Out More</div>
              </div>
            </div>
          </Link>

          <a onClick={toggleContactModal} className="ml-0 sm:ml-16">
            <div className="card mt-8 mx-auto rounded bg-white flex justify-center  items-center">
              <div className=" flex flex-col justify-center items-center">
                <img src={calendarImg} className="" />
                <div className="text-secondary mt-1 uppercase">Book Demo</div>
              </div>
            </div>
          </a>
      </div>
      </div>
    </div>
  )
}
