import React from "react"
import InfoGraphics from "../../images/e-invoicing-government/info-graphics.png";
import InfoGraphicsMobile from "../../images/e-invoicing-government/info-graphics-mobile.png";
import arrowImg from "../../images/partner/g-arrow.svg";
import steps_data from "./steps";
export default function Info({toggleContactModal}) {
    return (
        <section className="info value mx-auto flex flex-col items-center container">
            <img src={InfoGraphics} alt="info-graphics" className="hidden sm:block"/>
            <img src={InfoGraphicsMobile} alt="info-graphics" className=" sm:hidden" />
            <div className="blocks flex flex-col sm:flex-row sm:flex-wrap justify-between items-center">
                {steps_data.map((item, index) => (
                    <div className={`mb-4 sm:mb-0 block block${index} text-left`} key={`block-x-${index}`}>

                        <div className="title2 mb-2 font-medium text-17 text-neutral-1 leading-6">
                            {item.title}
                        </div>
                        <div
                            className="desc2 font-sans  text-neutral-3 text-sm
             mb-6 leading-6"
                        >
                            {item.desc}
                        </div>

                        <a
                            onClick={toggleContactModal}
                            // href={item.link}
                            className="link font-medium text-secondary flex items-center leading-18 cursor-pointer"
                        >
                            {item.but}
                            <img src={arrowImg} className="inline-block h-full ml-2" />
                        </a>
                    </div>
                ))}
            </div>
        </section>
    )
}
