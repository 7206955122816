import React from "react"
import logo1 from "../../images/e-invoicing-government/logo1.png"
import logo1Mobile from "../../images/e-invoicing-government/logo1-mobile.png"
import logo2 from "../../images/e-invoicing-government/logo2.png"
import logo2Mobile from "../../images/e-invoicing-government/logo2-mobile.png"
import logo3 from "../../images/e-invoicing-government/logo3.png"
import logo3Mobile from "../../images/e-invoicing-government/logo3-mobile.png"
import logo4 from "../../images/e-invoicing-government/logo4.png"
import logo4Mobile from "../../images/e-invoicing-government/logo4-mobile.png"

// import { meetingUrl } from "../../constants/url"

export default function Partners() {
  return (
    <section className="video-landing__partners">
      <div
        className="container lg:px-8 
       sm:flex sm:items-center sm:justify-between sm:pt-8 inner"
      >
        <div className="left mr-8 lb:mr-0">
          <h2 className="accredited font-medium">Accredited and trusted by</h2>
        </div>
        <div className="right ">
          <div className="logos">
            <img src={logo4Mobile} className="logo block sm:hidden" />
            <img src={logo4} className="logo hidden sm:block" />
            <img src={logo1Mobile} className="logo block ml-4 lg:ml-8 sm:hidden" />
            <img src={logo1} className="logo hidden  ml-4 lg:ml-8 sm:block" />
            <img src={logo2Mobile} className="logo ml-4 lg:ml-8 block sm:hidden" />
            <img src={logo2} className="logo ml-4 lg:ml-8 hidden sm:block" />
            <img src={logo3Mobile} className="logo ml-4 lg:ml-8 block sm:hidden" />
            <img src={logo3} className="logo ml-4 lg:ml-8 hidden sm:block" />
          </div>
        </div>
      </div>
    </section>
  )
}
